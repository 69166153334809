<template>
    <custom-revisions
        :fields="fields"
        :item="blog"
        :posts="blogs"
        :selected-items="selectedItems"
        :loading="loading || !blog"
        :reviews-type="'blog_revisions'"
        :return-back-route-name="'posts-list-blog'"
        @deleteRevision="deleteRevision($event)"
        @activateRevision="activateRevision($event)"
        @addSelectedItem="addSelectedItem($event)"
        @getRevisions="getRevisions($event)"
    />
</template>

<script>
import {
    ACTIONS_GET_BLOG_REVISIONS,
    ACTION_ACTIVATE_BLOG_REVISION,
    ACTION_DELETE_BLOG_REVISION
} from '@/store/modules/content/content-revisions/action-types'

import { revisionItems } from '@/helpers/constants/custom-editor/blog/create-new-blog-revision-items'

import { mapContentRevisionsFields } from '@/store/modules/content/content-revisions'
import { createNamespacedHelpers, mapState } from 'vuex';

import newRevisionsMixins from '@/mixins/revisions/newRevisionsMixins';

const {
    mapActions: mapContentRevisionsActions
} = createNamespacedHelpers('contentRevisions');

export default {
    name: 'IndexVue',
    mixins: [
        newRevisionsMixins
    ],
    computed: {
        ...mapContentRevisionsFields([
            'blog',
            'blog.revisions',
            'blog.post',
            'loading'
        ]),
        ...mapState('contentList', [
            'blogs'
        ]),
        fields() {
            return revisionItems
        }
    },
    methods: {
        ...mapContentRevisionsActions([
            ACTIONS_GET_BLOG_REVISIONS,
            ACTION_ACTIVATE_BLOG_REVISION,
            ACTION_DELETE_BLOG_REVISION
        ]),
        async getRevisions(event) {
            await this[ACTIONS_GET_BLOG_REVISIONS](event)
            this.selectedItems = []
        },
        activateRevision(event) {
            this[ACTION_ACTIVATE_BLOG_REVISION]({ post_id: this.post.id, revision_id: event.id })
                .then(() => {
                    this.$bus.$emit('showSnackBar', 'Your active revision was changed', 'success')
                })
        },
        deleteRevision(event) {
            this[ACTION_DELETE_BLOG_REVISION]({ post_id: this.post.id, revision_id: event.id })
                .then(() => {
                    this.$bus.$emit('showSnackBar', 'Success revision delete', 'error')
                })
        }
    }
}
</script>

<style scoped>

</style>
